@charset "utf-8";

html { font-size:14px !important;}

a,
pre,
[type='text'],
[type='password'],
[type='file'],
[type='email'],
select,
textarea {
	font-size: 1.6rem;
	color: #111;
	line-height: 1.6em;
}

.hide {
	position: absolute;
	width: 0.1rem;
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	clip-path: polygon(0 0, 0 0, 0 0);
}

#wrap {
	width: 100%;
	height: 100%;
	margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
#wrap.main {
	position: relative;
	/* background: #000; LGE_FIX */
}

/* common */
.btn {
	cursor: pointer;
}
.btn_default {
    display: inline-block;
	padding: 0.5rem 2rem;
    font-size: 1.8rem;
	color: #fff;
	background-color: rgba(0, 0, 255, 0.02);
	border-radius: 0.5rem;
    text-align: center;
}
.focus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10px);
    height: calc(100% - 10px);
}
.focus:hover,
.focus:focus {
    outline: none; /* LGE_FIX */
    line-height:4.1rem;
    border-radius: 0.5rem;
    border: solid 0.5rem #be1e42;
}


/* popup */
.popup_body {
	background-color: transparent !important;
	overflow: hidden;
}
.popup {
    display: none;
    position: absolute;
    bottom:0rem;
    left:0rem;
    width: 100%;
    /* height: 100%; */
    /* height: 35.3rem; */
    border-radius:1.0rem;
	overflow: hidden;
    background-color: #ecececbf;
    box-sizing: border-box;
}
.popup .popup_header {
	padding: 3rem 3rem 0rem;
}
.popup .popup_header h1{
	font-size: 1.8rem;
	font-weight: bold;
}
.popup .popup-content {
	padding: 2rem 3rem;
    /* height: calc(100% - 13.2rem); */
    font-size: 2.2rem;
	color: #555555;
    text-align: center;
    box-sizing: border-box;
}
.popup .popup-content .content_area .user_area {
    display: flex;
    flex-direction: row;
    align-items: center;
	padding-bottom: 2rem;
    text-align: left;
    color: #444444;
	font-size: 2.2rem;
	font-weight: bold;
}
.popup .popup-content .content_area .text {
    text-align: left;
    line-height: 1.6;
    word-break: keep-all;
    margin-bottom: 1rem;
}
.popup .popup-content .content_area .text strong.user {
    font-size: 2.2rem;
	font-weight: bold;
}

/* popup footer */
.popup .popup-footer {
    padding: 0rem 3rem 3rem;
    height: 5.1rem;
    display: flex;
}
.popup .popup-footer .popup_btn {
    display: block;
    width: 100%;
    line-height:5.1rem;
    font-size: 2.2rem;
	color: #444444;
	background-color: #cccccc80;
	border-radius: 1rem;
    text-align: center;
    outline: none;
    box-sizing: content-box;
    cursor: pointer;
}
.popup .popup-footer .popup_btn:hover,
.popup .popup-footer .popup_btn:focus {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    line-height:4.1rem;
    border-radius: 0.5rem;
    border: solid 0.5rem #be1e42;
}

.popup.off,
.popup.on {display: block;}

.popup.type1{
	animation: type_ani1 1000ms ease;
}
.popup.type2{
	animation: type_ani2 1000ms ease;
}
.popup.type3{
	animation: type_ani3 1500ms ease;
}
.popup.off.type1{
    bottom: -100%;
	animation: type_off_ani1 1000ms ease;
}
.popup.off.type2{
    left: 100%;
	animation: type_off_ani2 1000ms ease;
}
.popup.off.type3{
    opacity: 0;
	animation: type_off_ani3 1500ms ease;
}
@keyframes type_ani1{
    from{
        bottom: -100%; /* 애니메이션 시작 */
    }
    to{
        bottom: 0%; /* 애니메이션 종료 */
    }
}
@keyframes type_ani2{
    from{
        left: 100%; /* 애니메이션 시작 */
    }
    to{
        left: 0%; /* 애니메이션 종료 */
    }
}
@keyframes type_ani3{
    from{
        opacity: 0; /* 애니메이션 시작 */
    }
    to{
        opacity: 1; /* 애니메이션 종료 */
    }
}
@keyframes type_off_ani1{
    from{
        bottom: 0%; /* 애니메이션 시작 */
    }
    to{
        bottom: -100%; /* 애니메이션 종료 */
    }
}
@keyframes type_off_ani2{
    from{
        left: 0%; /* 애니메이션 시작 */
    }
    to{
        left: 100%; /* 애니메이션 종료 */
    }
}
@keyframes type_off_ani3{
    from{
        opacity: 1; /* 애니메이션 시작 */
    }
    to{
        opacity: 0; /* 애니메이션 종료 */
    }
}

@media screen and (max-height:720px){
	html { font-size:6.4px !important;}

    .focus {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
    }
    .focus:hover, 
    .focus:focus {
        outline: none; /* LGE_FIX */
        border-radius: 3px;
        border: solid 3px #be1e42; 
    }

    .popup {
        /* height: 22.9rem; */
        height: 229px;
        border-radius: 5px;
    }
    .popup .popup_header{
        padding: 20px 20px 0px;
    }
    .popup .popup_header h1 img {
        height: 18px;
    }
    .popup .popup-content {
        padding: 15px 20px;
        height: calc(100% - 93px);
    }
    .popup .popup-content .content_area .text {
        line-height: 1.4;
    }
    .popup.popup-preview {
        /* min-height: 307px; LGE_FIX */
    }
    .popup.popup-preview .popup-content .content_area .img_area li {
        height: 72px;
        border-radius: 5px;
    }
    .popup.popup-preview .popup-content .content_area .img_area .img_resize .focus {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
    }
    .popup.popup-preview .popup-content .content_area .img_area .img_one.img_resize .focus {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
    }
    .popup .popup-footer {
        padding: 0px 20px 20px;
        height: 35px;
    }
    .popup .popup-footer .popup_btn {
        line-height: 35px;
        border-radius: 5px;
    }
    .popup .popup-footer .popup_btn:hover, 
    .popup .popup-footer .popup_btn:focus {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        line-height: 29px;
        border: solid 3px #be1e42;
    }

    .popup.popup-alert .popup-content .content_area img {
        height: 70px;
    }
    .popup.popup-preview .popup-content .content_area .img_area li.img_one {
        width: 218px;
        height: 146px;
    }
    .popup.popup-preview .popup-content .content_area .link_area {
        height: 49px;
        border-radius: 5px;
        border: 0.5px solid #d3d3d3;
    }
    .popup.popup-preview .popup-content .content_area .link_area .thum {
        height: 49px;
    }
}

.main {
    padding: 2rem;
    box-sizing: border-box;
}
.frame_popup {
  display: none;
  position: absolute;
  bottom:3rem;
  right:4rem;
  width: 50rem;
  height: calc(100% - 6rem);
  border: none;
  overflow: hidden;
  background-color: transparent;
  box-sizing: border-box;
}
.frame_popup.on {display: block;}

@media screen and (max-height:720px){
  html { font-size:10px !important;}
  .frame_popup {
      width: 26rem;
  }
}