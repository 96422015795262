.camera-controls {
  display: flex;
  margin-left: 12px;
  justify-content: space-between;
  margin-bottom: 12px;
  /* padding: 10px; */
}

.my_log {
  width: 100%;
  height: 100%;
  top: 30px;      /* 상단으로부터 30px */
  left: 30px;    /* 우측으로부터 30px */
}


.Frame-4 {
  width: 320px;
  height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  /* padding: 12px; */
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed; /* 화면에 고정되도록 설정 */
  top: 30px;      /* 상단으로부터 30px */
  right: 30px;    /* 우측으로부터 30px */
}

.Rectangle-1 {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  /* margin: 12px; */
  align-self: stretch;
  flex-grow: 1;
  /* padding: 6px 254px 126px 6px; */
  width: 296px;
  height: 222px;
  border-radius: 3px;
}

.Frame-1 {
  height: 36px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  background-color: #616161;
  width: 248px;
  transition: transform 0.3s ease;
}

.Frame-1:focus {
  transform: scale(1.01);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.Frame-2 {
  width: 36px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 24px;
  background-color: #be1e42;
  margin-left: 12px;
  transition: transform 0.3s ease;
}

.Frame-2:focus {
  transform: scale(1.01);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.Frame-6 {
  position: absolute;
  width: 36px;
  height: 36px;
  margin-top: 18px;
  margin-left: 18px;
  display: flex; /* 이미지를 중앙에 배치하기 위해 flex를 활성화합니다. */
  justify-content: center; /* 가로 방향 중앙 정렬 */
  align-items: center; /* 세로 방향 중앙 정렬 */
  border-radius: 36px;
  background-color: rgba(28, 27, 27, 0.3);
}


.Door-Open {
  width: 74px;
  height: 14px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

img.Frame-5 {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

.img-Frame-6 {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

.hls-video {
  width: 296px;
  height: 222px;
}

@media (min-width: 5000px) {
  .camera-controls {
    margin-left: 24px;
    margin-bottom: 24px;
  }
  
  .Frame-4 {
    width: 640px;
    height: 480px;
    gap: 24px;
    border-radius: 12px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.25);
    top: 60px;      /* 상단으로부터 30px */
    right: 60px;    /* 우측으로부터 30px */
  }
  
  .Rectangle-1 {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    width: 592px;
    height: 336px;
    border-radius: 6px;
  }
  
  .Frame-1 {
    height: 72px;
    gap: 20px;
    padding: 20px;
    border-radius: 12px;
    width: 496px;
  }
  
  .Frame-1:focus {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
  }
  
  .Frame-2 {
    width: 72px;
    height: 72px;
    gap: 20px;
    padding: 10px;
    border-radius: 48px;
    margin-left: 24px;
  }
  
  .Frame-2:focus {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
  }
  
  .Frame-6 {
    width: 72px;
    height: 72px;
    margin-top: 36px;
    margin-left: 36px;
    border-radius: 72px;
  }
  
  
  .Door-Open {
    width: 148px;
    height: 28px;
    font-size: 28px;
  }
  
  img.Frame-5 {
    width: 48px;
    height: 48px;
  }
  
  .img-Frame-6 {
    width: 48px;
    height: 48px;
  }

  .hls-video {
    width: 592px;
    height: 336px;
  }
  
}
